import { render, staticRenderFns } from "./PageTeamManagement.vue?vue&type=template&id=397bf811&scoped=true"
import script from "./PageTeamManagement.vue?vue&type=script&lang=js"
export * from "./PageTeamManagement.vue?vue&type=script&lang=js"
import style0 from "./PageTeamManagement.vue?vue&type=style&index=0&id=397bf811&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397bf811",
  null
  
)

export default component.exports